import React, { Component, useState, useContext, useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import { withWhiteLabelContext } from "react-whitelabel"
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import Alert from "react-bootstrap/Alert"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"

import useFunnelExitModal from "../../hooks/useFunnelExitModal"
import useLocalStorage from "../../hooks/useLocalStorage"
import useRouter from "../../hooks/useRouter"
import { ModalProvider, ModalContext } from "../../context/ModalContext"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { routePaths } from "../../routes/routes"
import { keys, features, endpoints } from "../../globals.js"
import { fullHumanDateTime } from "../../utils/date-time"
import Form from "react-bootstrap/Form"
import CallAndChat from "../CallAndChat/CallAndChat"
import IndexPage from "../IndexPage/IndexPage.jsx"
import TripInfoWizard from "../TripInfoWizard/TripInfoWizard.jsx"
import SubmitAuction from "../SubmitAuction/SubmitAuction.jsx"
import AuctionStatus from "../AuctionStatus/AuctionStatus.jsx"
import AirportPage from "../AirportPage/AirportPage.jsx"
import Carnival from "../Carnival/Carnival.jsx"
import MarketLanding from "../MarketLanding/MarketLanding.jsx"
import Contest from "../ContestLanding/ContestLanding.jsx"
import CheckoutPage from "../CheckoutPage/CheckoutPage.jsx"
import Confirmation from "../Confirmation/Confirmation.jsx"
import Contact from "../Contact/Contact.jsx"
import CovidLanding from "../CovidLanding/CovidLanding.jsx"
import EventsPage from "../EventsPage/EventsPage.jsx"
import Giveaway from "../Giveaway/Giveaway.jsx"
import Page404 from "../Page404/Page404.jsx"
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy.jsx"
import SearchResultsPage from "../SearchResultsPage/SearchResultsPage.jsx"
import ServicesLanding from "../ServicesLanding/ServicesLanding.jsx"
import SiteTerms from "../SiteTerms/SiteTerms.jsx"
import VehiclesLanding from "../VehiclesLanding/VehiclesLanding.jsx"
import ThankYou from "../ThankYou/ThankYou.jsx"
import ThankYouLanding from "../ThankYouRequestForm/ThankYouRequestForm.jsx"
import RequestPhoneQuote from "../RequestPhoneQuote/RequestPhoneQuote.jsx"
import OperatorPage from "../OperatorPage/OperatorPage.jsx"
import SiteMap from "../SiteMap/SiteMap.jsx"
import "./BookingContainer.scss"

const stripePromise = loadStripe(keys.stripe)

const createInitialSearchFormErrorState = () => ({
  pickupPlace: "",
  dropoffPlace: "",
  date: "",
  hour: "",
  minute: "",
  email: "",
  serviceType: "",
  pax: "",
})

const createInitialTripWizardValues = () => ({
  date: false,
  hour: false,
  minute: false,
  isRoundTrip: undefined,
  isHourly: undefined,
  durationInHours: 0,
  returnDate: false,
  returnHour: false,
  returnMinute: false,
})

class BookingContainer extends Component {
  state = {
    searchForm: {
      rideDate: "",
      pickupPlace: "",
      dropoffPlace: "",
      email: "",
      roundTrip: false,
      returnDate: "",
    },
    // search parameters loaded in from active search pages
    bookingParamValues: {},
    searchFormErrors: createInitialSearchFormErrorState(),
    pickupPlace: {
      lat: 0.0,
      lng: 0.0,
      display: "",
      placeId: "",
      queryResults: [],
      isAirport: false,
      item: {},
      name: "",
    },
    dropoffPlace: {
      lat: 0.0,
      lng: 0.0,
      display: "",
      placeId: "",
      queryResults: [],
      isAirport: false,
      item: {},
      name: "",
    },
    searchPickupPlace: {
      lat: 0.0,
      lng: 0.0,
      display: "",
      placeId: "",
      queryResults: [],
      isAirport: false,
      item: {},
      name: "",
    },
    searchDropoffPlace: {
      lat: 0.0,
      lng: 0.0,
      display: "",
      placeId: "",
      queryResults: [],
      isAirport: false,
      item: {},
      name: "",
    },
    roundTrip: false,
    partySize: "",
    customPartySize: "",
    vehicleTypes: [1, 12],
    searchResults: [],
    secondLegResults: [],
    searchLocation: "",
    tripWizardValues: createInitialTripWizardValues(),
    cart: {
      pickupLeg: false,
      dropoffLeg: false,
    },
    completeDate: false,
    returnDate: false,
    finishedTripWizard: false,
    isHourly: false,
    searchIsHourly: false,
    bookedRide: {
      grandTotal: 0,
      isAirport: false,
      airlineCode: "",
      flightNumber: "",
    },
    searchURL: false,
    error: false,
    searchParams: {
      ride_date: "01/01/2030",
      pickup_time: "12:00",
      drop_off_time: "1:00",
      pickup_place_id: false,
      dropoff_place_id: false,
      pickup_place: "100 North Wiget Lane, Walnut Creek, CA 94598, US",
      drop_off_place: "200 Muir Road, Martinez, CA 94553, US",
      pax: 3,
      vehicle_types: [1, 3, 4, 5, 6, 7, 11, 12],
      service_type: 131072,
      email: "",
      bids: false,
    },
    search_uuid_number: "",
  }

  componentWillUpdate(prevProps, prevState) {
    const { searchUrl, finishedTripWizard, completeDate, partySize } =
      this.state

    if (
      searchUrl !== prevState.searchUrl ||
      finishedTripWizard !== prevState.finishedTripWizard ||
      partySize !== prevState.partySize
    ) {
      this.props.updateIntercom({
        "Search Url": searchUrl || null,
        "Trip Date": finishedTripWizard
          ? fullHumanDateTime(completeDate)
          : null,
        Passengers: partySize || null,
      })
    }
  }

  setSearchParams = searchParams => {
    this.setState({ searchParams })
  }

  setSearchFormErrors = errs => {
    this.setState({ searchFormErrors: errs })
  }

  setSearchURL = url => {
    this.setState({ searchURL: url })
  }

  loadBookingState = () => {}

  onSearchFormChange = (name, value) => {
    this.setState({ searchForm: { ...this.state.searchForm, [name]: value } })
  }

  setPlace = (
    item,
    key,
    lat,
    lng,
    displayName,
    placeId,
    isAirport,
    airportCode
  ) => {
    this.setState({
      [key]: {
        ...this.state[key],
        lat: lat,
        lng: lng,
        display: displayName,
        placeId: placeId,
        item,
        isAirport,
        airportCode,
      },
    })
  }

  setRoundTrip = val => {
    this.setState({ roundTrip: val })
  }

  setTripWizardValue = (key, value, callback) => {
    let fullDate = this.state.completeDate
    let finishedTripWizard = false
    if (key === "date") {
      fullDate = value
    }
    if (key === "hour") {
      fullDate = fullDate.set({ hour: value })
    }
    if (key === "minute") {
      fullDate = fullDate.set({ minute: value })
    }
    if (
      key === "returnMinute" || // p2p round trip
      (key === "isHourly" &&
        !value &&
        !this.state.pickupPlace.isAirport &&
        !this.state.dropoffPlace?.isAirport) || // p2p non-airport
      key === "tripType" || // hourly
      (key === "isRoundTrip" && !value)
    ) {
      // p2p round-trip not selected
      finishedTripWizard = true
    }

    let returnDate = this.state.returnDate
    if (key === "returnDate") {
      returnDate = value
    }
    if (key === "returnHour") {
      returnDate = returnDate.set({ hour: value })
    }
    if (key === "returnMinute") {
      returnDate = returnDate.set({ minute: value })
    }
    this.setState(
      {
        tripWizardValues: { ...this.state.tripWizardValues, [key]: value },
        finishedTripWizard,
        completeDate: fullDate,
        returnDate,
      },
      callback
    )
  }

  clearTripWizard = () => {
    this.setState({
      tripWizardValues: createInitialTripWizardValues(),
      completeDate: false,
      returnDate: false,
    })
  }

  setCompleteDate = (date, returnDate, duration, isHourly) => {
    this.setState({
      completeDate: date,
      returnDate: returnDate,
      tripWizardValues: {
        ...this.state.tripWizardValues,
        date,
        durationInHours: duration,
        hour: date.hour,
        minute: date.minute,
        isHourly,
      },
      finishedTripWizard: true,
    })
  }

  onPlaceQuery = (key, queryResults) => {
    this.setState({
      [key]: { ...this.state[key], queryResults },
    })
  }

  setSearchResults = (
    results,
    returnResults,
    pickupPlace,
    dropoffPlace,
    isHourly,
    callback
  ) => {
    const urlParams = new URLSearchParams(window.location.search)
    const pickupPlaceObj = {
      lat: pickupPlace.lat,
      lng: pickupPlace.lng,
      display: pickupPlace.pretty
        ? pickupPlace.pretty
        : pickupPlace.display_address === undefined
        ? this.state.pickupPlace.display
        : pickupPlace.display_address,
      airportCode: pickupPlace.is_airport ? urlParams.get("pickup_place") : "",
      isAirport: !!pickupPlace.is_airport,
      placeId: "",
      queryResults: [],
      name: pickupPlace.name,
    }
    const dropoffPlaceObj = dropoffPlace
      ? {
          lat: dropoffPlace.lat,
          lng: dropoffPlace.lng,
          display: dropoffPlace.pretty
            ? dropoffPlace.pretty
            : dropoffPlace.display_address,
          airportCode: dropoffPlace.is_airport
            ? urlParams.get("drop_off_place")
            : "",
          isAirport: !!dropoffPlace.is_airport,
          placeId: "",
          queryResults: [],
          name: dropoffPlace.name,
        }
      : false
    // XXX TODO update searchResults and and secondLegResults
    this.setState(
      {
        searchResults: results,
        secondLegResults: returnResults,
        searchPickupPlace: pickupPlaceObj,
        searchDropoffPlace: dropoffPlaceObj,
        searchIsHourly: isHourly,
      },
      callback
    )
  }
  setVehicleOptions = opts => {
    this.setState({ partySize: opts.size, vehicleTypes: opts.types })
  }

  setCustomPartySize = v => {
    this.setState({ customPartySize: v })
  }

  addItemToCart = (
    item,
    key,
    callback,
    resultHandle1,
    resultHandle2,
    resultHandle1Data,
    resultHandle2Data
  ) => {
    this.setState(
      {
        cart: {
          ...this.state.cart,
          [key]: item,
          resultHandle1: resultHandle1,
          resultHandle2: resultHandle2,
          resultHandle1Data: resultHandle1Data,
          resultHandle2Data: resultHandle2Data,
        },
      },
      callback
    )
  }

  setBookedRide = ride => {
    this.setState({ bookedRide: ride })
  }

  openChatWindow = () => {
    this.props.handleOpenIntercom({
      "Search Url": this.state.searchURL,
      "Trip Date": this.state.finishedTripWizard
        ? fullHumanDateTime(this.state.completeDate)
        : false,
      Passengers: this.state.partySize,
    })
  }

  setError = err => {
    this.setState({ error: err }, () => window.scrollTo(0, 0))
  }

  render() {
    const brand = this.props.label
    if (features.siteDown) {
      return (
        <Container
          className="container bookingContainerRoot"
          style={{ minHeight: "0", marginTop: 124 }}
        >
          <h1>{brand.domain} Is Offline - Performing Maintenance</h1>
          <h2>
            We'll be back online soon. We apologize for the inconvenience.
          </h2>
        </Container>
      )
    }
    return (
      <>
        <div className="bookingContainerRoot">
          {this.state.error ? (
            <Container style={{ marginTop: 124 }}>
              {/* <Alert variant="danger" className="serverError">
                <Row className="center">
                  <Col
                    xs={12}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 6, offset: 3 }}
                  >
                    Oh no! We’re experiencing some technical difficulties. A
                    booking specialist can help complete your booking.
                  </Col>
                </Row>
                <CallAndChat openChatWindow={this.openChatWindow} />
              </Alert> */}
              <Alert variant="success">
                <Row className="center">
                  <Col
                    xs={12}
                    md={{ span: 8, offset: 2 }}
                    lg={{ span: 6, offset: 3 }}
                  >
                    Form submitted
                  </Col>
                </Row>
                <CallAndChat openChatWindow={this.openChatWindow} />
              </Alert>
            </Container>
          ) : (
            <></>
          )}
          <Switch>
            {/* <Route path={routePaths.root} exact>
              <IndexPage
                  pickupPlace={this.state.pickupPlace}
                  dropoffPlace={this.state.dropoffPlace}
                  setPlace={this.setPlace}
                  setVehicleOptions={this.setVehicleOptions}
                  partySize={this.state.partySize}
                  customPartySize={this.state.customPartySize}
                  setCustomPartySize={this.setCustomPartySize}
                  vehicleTypes={this.state.vehicleTypes}
                  pickupPlaceItem={this.state.pickupPlace.item}
                  onPlaceQuery={this.onPlaceQuery}
                  pickupPlaceQueryResults={this.state.pickupPlace.queryResults}
                  dropoffPlaceQueryResults={this.state.dropoffPlace.queryResults}
                  tripWizardValues={this.state.tripWizardValues}
                  setSearchFormErrors={this.setSearchFormErrors}
                  searchFormErrors={this.state.searchFormErrors}
                  completeDate={this.state.completeDate}
                  returnDate={this.state.returnDate}
                  roundTrip={this.state.roundTrip}
                  setRoundTrip={this.setRoundTrip}
                  finishedTripWizard={this.state.finishedTripWizard}
                  finishedReturnTripWizard={this.state.finishedReturnTripWizard}
                  createInitialSearchFormErrorState={
                    createInitialSearchFormErrorState
                  }
                  openChatWindow={this.openChatWindow}
                  errors={this.state.searchFormErrors}
                  setError={this.setError}
                  onSearchFormChange={this.onSearchFormChange}
                  searchForm={this.state.searchForm}
                  setSearchParams={this.setSearchParams}
                  vehicleImage={this.props.label.vehicleImage}
                />
            </Route> */}
            <Route path={routePaths.root} exact>
              <IndexPage
                pickupPlace={this.state.pickupPlace}
                dropoffPlace={this.state.dropoffPlace}
                setPlace={this.setPlace}
                setVehicleOptions={this.setVehicleOptions}
                partySize={this.state.partySize}
                customPartySize={this.state.customPartySize}
                setCustomPartySize={this.setCustomPartySize}
                vehicleTypes={this.state.vehicleTypes}
                pickupPlaceItem={this.state.pickupPlace.item}
                onPlaceQuery={this.onPlaceQuery}
                pickupPlaceQueryResults={this.state.pickupPlace.queryResults}
                dropoffPlaceQueryResults={this.state.dropoffPlace.queryResults}
                tripWizardValues={this.state.tripWizardValues}
                setSearchFormErrors={this.setSearchFormErrors}
                searchFormErrors={this.state.searchFormErrors}
                completeDate={this.state.completeDate}
                returnDate={this.state.returnDate}
                roundTrip={this.state.roundTrip}
                setRoundTrip={this.setRoundTrip}
                finishedTripWizard={this.state.finishedTripWizard}
                finishedReturnTripWizard={this.state.finishedReturnTripWizard}
                createInitialSearchFormErrorState={
                  createInitialSearchFormErrorState
                }
                openChatWindow={this.openChatWindow}
                errors={this.state.searchFormErrors}
                setError={this.setError}
                onSearchFormChange={this.onSearchFormChange}
                searchForm={this.state.searchForm}
                setSearchParams={this.setSearchParams}
                vehicleImage={this.props.label.vehicleImage}
              />
            </Route>
            <Route path={routePaths.thankyou} exact>
              <ThankYou />
            </Route>
            <Route path={routePaths.thankyouLanding} exact>
              <ThankYouLanding />
            </Route>
            <Route path={routePaths.airport} exact>
              <AirportPage
                pickupPlace={this.state.pickupPlace}
                dropoffPlace={this.state.dropoffPlace}
                setPlace={this.setPlace}
                setVehicleOptions={this.setVehicleOptions}
                partySize={this.state.partySize}
                vehicleTypes={this.state.vehicleTypes}
                pickupPlaceItem={this.state.pickupPlace.item}
                onPlaceQuery={this.onPlaceQuery}
                pickupPlaceQueryResults={this.state.pickupPlace.queryResults}
                dropoffPlaceQueryResults={this.state.dropoffPlace.queryResults}
                tripWizardValues={this.state.tripWizardValues}
                setSearchFormErrors={this.setSearchFormErrors}
                searchFormErrors={this.state.searchFormErrors}
                completeDate={this.state.completeDate}
                finishedTripWizard={this.state.finishedTripWizard}
                createInitialSearchFormErrorState={
                  createInitialSearchFormErrorState
                }
                openChatWindow={this.openChatWindow}
                errors={this.state.searchFormErrors}
                setError={this.setError}
                customPartySize={this.state.customPartySize}
                setCustomPartySize={this.setCustomPartySize}
              />
            </Route>
            <Route path={routePaths.tripWizard.root}>
              <TripInfoWizard
                setTripWizardValue={this.setTripWizardValue}
                tripWizardValues={this.state.tripWizardValues}
                clearTripWizard={this.clearTripWizard}
                completeDate={this.state.completeDate}
                returnDate={this.state.returnDate}
                pickupPlace={this.state.pickupPlace}
                dropoffPlace={this.state.dropoffPlace}
              />
            </Route>
            <Route path={routePaths.searchResults} exact>
              <SearchResultsPage
                bookingParamValues={this.state.bookingParamValues}
                setBoookingParamValues={bookingParamValues =>
                  this.setState({
                    bookingParamValues,
                  })
                }
                searchResults={this.state.searchResults}
                setSearchResults={this.setSearchResults}
                secondLegResults={this.state.secondLegResults}
                addItemToCart={this.addItemToCart}
                setCompleteDate={this.setCompleteDate}
                completeDate={this.state.completeDate}
                returnDate={this.state.returnDate}
                pickupPlace={this.state.pickupPlace}
                dropoffPlace={this.state.dropoffPlace}
                searchLocation={this.state.searchURL}
                heroColor={this.props.label.colorStatic}
                isHourly={this.state.isHourly}
                setSearchURL={this.setSearchURL}
                setError={this.setError}
                openChatWindow={this.openChatWindow}
                stripe={stripePromise}
                pax={this.state.partySize}
                customPartySize={this.state.customPartySize}
                setUUIDNumber={uuid => {
                  this.setState({
                    search_uuid_number: uuid,
                  })
                }}
              ></SearchResultsPage>
            </Route>
            <Route path={routePaths.checkout} exact>
              <Elements
                stripe={stripePromise}
                options={{
                  fonts: [
                    {
                      cssSrc: brand.fontSource,
                    },
                  ],
                }}
              >
                <ElementsConsumer>
                  {context => (
                    <CheckoutPage
                      bookingParamValues={this.state.bookingParamValues}
                      cart={this.state.cart}
                      setBookedRide={this.setBookedRide}
                      pickupPlace={this.state.searchPickupPlace}
                      dropoffPlace={this.state.searchDropoffPlace}
                      isHourly={this.state.searchIsHourly}
                      completeDate={this.state.completeDate}
                      returnDate={this.state.returnDate}
                      loadBookingState={this.loadBookingState}
                      stripe={context.stripe}
                      elements={context.elements}
                      setError={this.setError}
                      search_uuid_number={this.state.search_uuid_number}
                    />
                  )}
                </ElementsConsumer>
              </Elements>
            </Route>
            <Route path={routePaths.confirmation} exact>
              {this.state.cart.dropoffLeg ? (
                <Container>
                  {/* <Row>
                    <Col
                      xs={12}
                      md={{ span: 8, offset: 2 }}
                      lg={{ span: 6, offset: 3 }}
                    >
                      <br />
                      <br />
                      <h1>Departure Trip</h1>
                      <em>View Return Trip Below</em>
                      <hr />
                    </Col>
                  </Row> */}
                </Container>
              ) : (
                <></>
              )}
              <Confirmation
                bookingParamValues={this.state.bookingParamValues}
                cart={{
                  ...this.state.cart,
                  pickupLeg: this.state.cart.pickupLeg,
                }}
                pickupPlace={this.state.searchPickupPlace}
                dropoffPlace={this.state.searchDropoffPlace}
                isHourly={this.state.searchIsHourly}
                hourlyDuration={
                  this.props.tripWizardValues &&
                  this.props.tripWizardValues.durationInHours
                }
                completeDate={this.state.completeDate}
                bookedRide={this.state.bookedRide}
                loadBookingState={this.loadBookingState}
                openChatWindow={this.openChatWindow}
                setError={this.setError}
                recordGAValue={true}
              />
              {this.state.cart.dropoffLeg ? (
                <>
                  <Container>
                    <Row>
                      <Col
                        xs={12}
                        md={{ span: 8, offset: 2 }}
                        lg={{ span: 6, offset: 3 }}
                      >
                        <br />
                        <br />
                        <h1>Return Trip</h1>
                        <hr />
                      </Col>
                    </Row>
                  </Container>
                  <Confirmation
                    cart={{
                      ...this.state.cart,
                      pickupLeg: this.state.cart.dropoffLeg,
                    }}
                    pickupPlace={this.state.searchDropoffPlace}
                    dropoffPlace={this.state.searchPickupPlace}
                    isHourly={this.state.searchIsHourly}
                    hourlyDuration={
                      this.props.tripWizardValues &&
                      this.props.tripWizardValues.durationInHours
                    }
                    completeDate={this.state.returnDate}
                    bookedRide={this.state.bookedRide}
                    loadBookingState={this.loadBookingState}
                    openChatWindow={this.openChatWindow}
                    setError={this.setError}
                    recordGAValue={false}
                  />
                </>
              ) : (
                <></>
              )}
            </Route>
            <Route path={routePaths.contact} exact>
              <Contact openChatWindow={this.openChatWindow} />
            </Route>
            <Route path={routePaths.covid} exact>
              <CovidLanding />
            </Route>
            <Route path={routePaths.services} exact>
              <ServicesLanding />
            </Route>
            <Route path={routePaths.vehicles} exact>
              <VehiclesLanding />
            </Route>
            <Route path={routePaths.events} exact>
              <EventsPage />
            </Route>
            <Route path={routePaths.giveaway} exact>
              <Giveaway />
            </Route>
            <Route path={routePaths.siteTerms} exact>
              <SiteTerms />
            </Route>
            <Route path={routePaths.privacyPolicy} exact>
              <PrivacyPolicy />
            </Route>
            <Route path={routePaths.submitAuction}>
              <SubmitAuction
                setSearchParams={this.setSearchParams}
                searchParams={this.state.searchParams}
                searchResults={this.state.searchResults}
                setSearchResults={this.setSearchResults}
                setCompleteDate={this.setCompleteDate}
                completeDate={this.state.completeDate}
                pickupPlace={this.state.pickupPlace}
                dropoffPlace={this.state.dropoffPlace}
                isHourly={this.state.isHourly}
                setError={this.setError}
              />
            </Route>
            <Route path={routePaths.auctionStatus}>
              <AuctionStatus
                searchResults={this.state.searchResults}
                completeDate={this.state.completeDate}
                pickupPlace={this.state.pickupPlace}
                dropoffPlace={this.state.dropoffPlace}
                addItemToCart={this.addItemToCart}
              />
            </Route>
            <Route path={routePaths.contest} exact>
              <Contest />
            </Route>
            <Route path={routePaths.carnival} exact>
              <Carnival
                pickupPlace={this.state.pickupPlace}
                dropoffPlace={this.state.dropoffPlace}
                setPlace={this.setPlace}
                setVehicleOptions={this.setVehicleOptions}
                partySize={this.state.partySize}
                vehicleTypes={this.state.vehicleTypes}
                pickupPlaceItem={this.state.pickupPlace.item}
                onPlaceQuery={this.onPlaceQuery}
                pickupPlaceQueryResults={this.state.pickupPlace.queryResults}
                dropoffPlaceQueryResults={this.state.dropoffPlace.queryResults}
                tripWizardValues={this.state.tripWizardValues}
                setSearchFormErrors={this.setSearchFormErrors}
                searchFormErrors={this.state.searchFormErrors}
                completeDate={this.state.completeDate}
                finishedTripWizard={this.state.finishedTripWizard}
                createInitialSearchFormErrorState={
                  createInitialSearchFormErrorState
                }
                openChatWindow={this.openChatWindow}
                errors={this.state.searchFormErrors}
                setError={this.setError}
                customPartySize={this.state.customPartySize}
                setCustomPartySize={this.setCustomPartySize}
                returnDate={this.state.returnDate}
                searchForm={this.state.searchForm}
                setSearchParams={this.setSearchParams}
                onSearchFormChange={this.onSearchFormChange}
              />
            </Route>
            <Route path={routePaths.requestQuote} exact>
              <MarketLanding
                pickupPlace={this.state.pickupPlace}
                dropoffPlace={this.state.dropoffPlace}
                setPlace={this.setPlace}
                setVehicleOptions={this.setVehicleOptions}
                partySize={this.state.partySize}
                vehicleTypes={this.state.vehicleTypes}
                pickupPlaceItem={this.state.pickupPlace.item}
                onPlaceQuery={this.onPlaceQuery}
                pickupPlaceQueryResults={this.state.pickupPlace.queryResults}
                dropoffPlaceQueryResults={this.state.dropoffPlace.queryResults}
                tripWizardValues={this.state.tripWizardValues}
                setSearchFormErrors={this.setSearchFormErrors}
                searchFormErrors={this.state.searchFormErrors}
                completeDate={this.state.completeDate}
                finishedTripWizard={this.state.finishedTripWizard}
                createInitialSearchFormErrorState={
                  createInitialSearchFormErrorState
                }
                openChatWindow={this.openChatWindow}
                errors={this.state.searchFormErrors}
                setError={this.setError}
                customPartySize={this.state.customPartySize}
                setCustomPartySize={this.setCustomPartySize}
                returnDate={this.state.returnDate}
                searchForm={this.state.searchForm}
                setSearchParams={this.setSearchParams}
                onSearchFormChange={this.onSearchFormChange}
                pageName={this.pageName}
              />
            </Route>
            <Route path={routePaths.requestPhoneQuote} exact>
              <RequestPhoneQuote
                pickupPlace={this.state.pickupPlace}
                dropoffPlace={this.state.dropoffPlace}
                setPlace={this.setPlace}
                setVehicleOptions={this.setVehicleOptions}
                partySize={this.state.partySize}
                vehicleTypes={this.state.vehicleTypes}
                pickupPlaceItem={this.state.pickupPlace.item}
                onPlaceQuery={this.onPlaceQuery}
                pickupPlaceQueryResults={this.state.pickupPlace.queryResults}
                dropoffPlaceQueryResults={this.state.dropoffPlace.queryResults}
                tripWizardValues={this.state.tripWizardValues}
                setSearchFormErrors={this.setSearchFormErrors}
                searchFormErrors={this.state.searchFormErrors}
                completeDate={this.state.completeDate}
                finishedTripWizard={this.state.finishedTripWizard}
                createInitialSearchFormErrorState={
                  createInitialSearchFormErrorState
                }
                openChatWindow={this.openChatWindow}
                errors={this.state.searchFormErrors}
                setError={this.setError}
                customPartySize={this.state.customPartySize}
                setCustomPartySize={this.setCustomPartySize}
                returnDate={this.state.returnDate}
                searchForm={this.state.searchForm}
                setSearchParams={this.setSearchParams}
                onSearchFormChange={this.onSearchFormChange}
                pageName={this.pageName}
              />
            </Route>
            <Route path={routePaths.operator} exact>
              <OperatorPage></OperatorPage>
            </Route>
            <Route path={routePaths.siteMap}>
              <SiteMap></SiteMap>
            </Route>
            <Route component={Page404} />
          </Switch>
        </div>
      </>
    )
  }
}

const BeforeExitWrapper = props => {
  const { pathname: hopOffPage } = useRouter()
  const { modalShown, setModalShown } = useContext(ModalContext)
  const [funnelExitPromoUsed, setFunnelExitPromoUsed] = useLocalStorage(
    "LIMOS-funnelExitPromoUsed"
  )

  const [userId] = useLocalStorage("LIMOS-userId")

  const [disallowExit, setDisallowExit] = useState(true)
  const [formError, setFormError] = useState(false)
  const [showPromoCode, setShowPromoCode] = useState(false)
  const [userPromoCode, setUserPromoCode] = useState(false)
  const [reason, setReason] = useState("")
  const [otherReason, setOtherReason] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")

  const validReasons = ["too-expensive", "no-vehicle", "more-time", "other"]

  const { OnBeforeUnloadPrompt } = useFunnelExitModal(
    disallowExit || !funnelExitPromoUsed
  )

  const handleClose = () => {
    setModalShown(false)
    setShowPromoCode(false)
    setReason("")
    setOtherReason("")
    setEmail("")
    setPhone("")
  }

  useEffect(() => {
    if (hopOffPage === "/a/confirmation") {
      setDisallowExit(false)
    }
  }, [hopOffPage])

  const validateForm = () => {
    const phoneRegex =
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const phoneValid = phoneRegex.test(phone)
    const emailValid = emailRegex.test(email)

    const enteredEmailOrPhone = email?.length || phone?.length

    if (!enteredEmailOrPhone) {
      console.log("no phone or email")
      return false
    } else if (email?.length) {
      if (!emailValid) {
        console.log("email fail")
        return false
      }
    } else if (phone?.length) {
      if (!phoneValid) {
        console.log("phone fail")
        return false
      }
    }

    if (!validReasons.includes(reason)) {
      console.log("no reason")
      return false
    }

    if (reason === "other" && otherReason.length < 20) {
      console.log("no other")
      return false
    }

    return true
  }

  const handleFeedbackSubmission = async () => {
    if (formError) {
      setFormError(false)
    }

    if (validateForm()) {
      let promoCode = "EXIT15"
      try {
        const res = await fetch(
          `${endpoints.limos}/v1/biz/reservations/custom_promo_code?api_key=${keys.limos}`
        )

        const body = await res.json()

        if (body.name) {
          promoCode = body.name
        }
      } catch (e) {
        console.log("promo get error ", e)
      }

      setUserPromoCode(promoCode)
      setShowPromoCode(true)

      try {
        const res = await fetch(
          `${endpoints.limos}/v1/biz/reservations/save_exit_survey_data`,
          {
            method: "PUT",
            body: JSON.stringify({
              api_key: keys.limos,
              name: userId,
              phone,
              email,
              reason,
              other_reason: otherReason,
              hop_off_page: hopOffPage,
            }),
          }
        )

        if (typeof window.dataLayer !== "undefined") {
          window.dataLayer.push({
            event: "exit-survey-completed",
            userId,
          })
        }

        if (res.status === 200) {
          console.log("successfuly stored exit reason to database")
        }
      } catch (e) {
        console.log("save exit reason error ", e.message)
      }

      if (!funnelExitPromoUsed) {
        process.env.NODE_ENV === "production" && setFunnelExitPromoUsed(true)
      }
    } else {
      setFormError(true)
    }
  }

  const inputStyles = { width: "100%", margin: "8px 0", padding: 8 }
  const enteredEmailOrPhone = email?.length || phone?.length
  const buttonDisabled =
    !validReasons.includes(reason) ||
    (reason === "other" && otherReason.length <= 20) ||
    !enteredEmailOrPhone

  return (
    <>
      <OnBeforeUnloadPrompt />
      <BookingContainer {...props} />

      {/*   <Modal
        show={modalShown}
        onHide={() => setModalShown(false)}
        className="cancel-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {showPromoCode
              ? "Save on your Booking!"
              : "We Appreciate Your Feedback!"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {showPromoCode ? (
            <p>
              Use Promo Code <strong>{userPromoCode}</strong> at the checkout
              within the next 24 hours to get 15% off your booking!
            </p>
          ) : (
            <>
              <p style={{ marginTop: 16 }}>
                We value your business and are concerned you didn’t book
                anything.
              </p>
              <p>
                Please let us know why you didn’t book and we will send you a
                Special Promotion Code for a Discount on this purchase.
              </p>
              <Form>
                <div style={{ marginLeft: 16 }}>
                  <Form.Check
                    onChange={(e) => setReason(e.target.name)}
                    checked={reason === "too-expensive"}
                    type="radio"
                    name="too-expensive"
                    label="Too Expensive"
                  />
                  <Form.Check
                    onChange={(e) => setReason(e.target.name)}
                    checked={reason === "no-vehicle"}
                    type="radio"
                    name="no-vehicle"
                    label="Vehicle Needed Not Available"
                  />
                  <Form.Check
                    onChange={(e) => setReason(e.target.name)}
                    checked={reason === "more-time"}
                    type="radio"
                    name="more-time"
                    label="Need More Time to Decide"
                  />
                  <Form.Check
                    onChange={(e) => setReason(e.target.name)}
                    checked={reason === "other"}
                    type="radio"
                    name="other"
                    label="Other"
                  />
                </div>
                {reason === "other" && (
                  <>
                    <textarea
                      value={otherReason}
                      onChange={(e) => setOtherReason(e.target.value)}
                      style={inputStyles}
                    />
                    <p
                      style={{
                        color: otherReason.length < 20 ? "orange" : "green",
                      }}
                    >
                      {otherReason.length} / 20
                    </p>
                  </>
                )}
                <p style={{ marginTop: 16 }}>
                  Please leave either your phone or email to send you a promo
                  code.
                </p>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Email"
                  aria-label="Email"
                  style={inputStyles}
                />
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="tel"
                  placeholder="Phone"
                  aria-label="phone"
                  style={inputStyles}
                />
              </Form>
            </>
          )}
          {formError && (
            <p style={{ color: "red" }}>
              Ensure you entered your phone or email and feedback and try again!
            </p>
          )}
        </Modal.Body>

        <Modal.Footer>
          {showPromoCode ? (
            <Button onClick={handleClose} variant="primary">
              Continue Checking Out
            </Button>
          ) : (
            <Button
              disabled={buttonDisabled}
              onClick={handleFeedbackSubmission}
              variant="primary"
            >
              Get My Promo Code!
            </Button>
          )}
        </Modal.Footer>
      </Modal> */}
    </>
  )
}

const ProviderLayer = props => {
  return (
    <ModalProvider>
      <BeforeExitWrapper {...props} />
    </ModalProvider>
  )
}

export default withWhiteLabelContext(ProviderLayer)
