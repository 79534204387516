import React, { useContext } from "react"
import { WhiteLabelContext } from "react-whitelabel"
import { Route, Switch, Link } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import SearchForm from "../SearchForm/SearchForm.jsx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useForm } from "react-hook-form"
import { routePaths } from "../../routes/routes"
import NewSearchForm from "../RequestQuoteForm/RequestForm.jsx"
import ContactForm from "../ContactForm/ContactForm.jsx"
import FaqListing from "../FaqListing/FaqListing.jsx"
import GoogleMap from "../GoogleMap/GoogleMap.jsx"
import SocialLinks from "../SocialLinks/SocialLinks.jsx"

import "./MarketLanding.scss"

const MarketLanding = props => {
  const { searchFormTitle, searchFormSubtitle, searchFormDescription } =
    useContext(WhiteLabelContext)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  })

  const reviewSection = () => {
    return (
      <div className="review-main-content" style={{ color: "#212529" }}>
        <h2>Over 1,797+ reviews</h2>

        <div
          className="yotpo-popup-box-small yotpo-nav yotpo-nav-primary "
          role="tablist"
        >
          <ul className="">
            <li
              className="yotpo-nav-tab yotpo-active"
              data-type="site"
              role="tab"
              tabIndex="0"
              aria-selected="true"
              data-content="yotpo-testimonials-site-reviews yotpo-testimonials-site-bottomline yotpo-site-reviews-tab"
              aria-controls="yotpo-testimonials-site-reviews yotpo-testimonials-site-bottomline yotpo-site-reviews-tab"
            >
              <div className="yotpo-nav-wrapper">
                <span className=""> Site Reviews </span>
              </div>
            </li>
          </ul>
        </div>

        <div className="yotpo">
          <div className="yotpo-review yotpo-regular-box">
            <div className="yotpo-header yotpo-verified-buyer ">
              <span className="yotpo-icon-profile yotpo-header-element pull-left">
                <span className="yotpo-user-letter">T</span>
                <span
                  className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                  data-type="toggleFade"
                  data-target="yotpo-tool-tip"
                  data-target-container="yotpo-header"
                ></span>
              </span>
              <div className="yotpo-header-element ">
                <span
                  className="y-label yotpo-user-name yotpo-font-bold pull-left"
                  aria-level="3"
                >
                  Thomas D.
                </span>
                <div className="label-with-tooltip pull-left" aria-level="3">
                  <span
                    className="y-label yotpo-user-title yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    aria-describedby="274825440"
                  >
                    Verified Buyer
                  </span>
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-stars">
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                </div>
                <div className="yotpo-clr"></div>
              </div>
              <div className="yotpo-header-element yotpo-header-actions ">
                <span className="y-label yotpo-review-date">06/05/23</span>
              </div>
            </div>
            <div className="yotpo-main ">
              <div
                className="content-title yotpo-font-bold"
                role="heading"
                aria-level="3"
              >
                Safe and luxurious!
              </div>
              <div className="yotpo-clr"></div>
              <div className="yotpo-review-wrapper">
                <div className="content-review">
                  Our travel too and from the airport was on time, courteous,
                  safe and comfortable.
                </div>
              </div>
            </div>
            <div className="yotpo-footer "></div>
          </div>
        </div>

        <div className="yotpo">
          <div className="yotpo-review yotpo-regular-box">
            <div className="yotpo-header yotpo-verified-buyer ">
              <span className="yotpo-icon-profile yotpo-header-element pull-left">
                <span className="yotpo-user-letter">J</span>
                <span
                  className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                  data-type="toggleFade"
                  data-target="yotpo-tool-tip"
                  data-target-container="yotpo-header"
                ></span>
              </span>
              <div className="yotpo-header-element ">
                <span className="y-label yotpo-user-name yotpo-font-bold pull-left">
                  Jonathan F
                </span>
                <div className="label-with-tooltip pull-left">
                  <span
                    className="y-label yotpo-user-title yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    aria-describedby="274825440"
                  >
                    Verified Buyer
                  </span>
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-stars ">
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                </div>
                <div className="yotpo-clr"></div>
              </div>
              <div className="yotpo-header-element yotpo-header-actions ">
                <span className="y-label yotpo-review-date"> 05/07/23</span>
              </div>
            </div>
            <div className="yotpo-main ">
              <div
                className="content-title yotpo-font-bold"
                role="heading"
                aria-level="3"
              >
                Great!
              </div>
              <div className="yotpo-clr"></div>
              <div className="yotpo-review-wrapper">
                <div className="content-review">Great</div>
              </div>
            </div>
            <div className="yotpo-footer "></div>
          </div>
        </div>

        <div className="yotpo">
          <div className="yotpo-review yotpo-regular-box">
            <div className="yotpo-header yotpo-verified-buyer ">
              <span className="yotpo-icon-profile yotpo-header-element pull-left">
                <span className="yotpo-user-letter">P</span>
                <span
                  className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                  data-type="toggleFade"
                  data-target="yotpo-tool-tip"
                  data-target-container="yotpo-header"
                ></span>
              </span>
              <div className="yotpo-header-element ">
                <span
                  className="y-label yotpo-user-name yotpo-font-bold pull-left"
                  aria-level="3"
                >
                  Patricia L.
                </span>
                <div className="label-with-tooltip pull-left" aria-level="3">
                  <span
                    className="y-label yotpo-user-title yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    aria-describedby="274825440"
                  >
                    Verified Buyer
                  </span>
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-stars">
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                </div>
                <div className="yotpo-clr"></div>
              </div>
              <div className="yotpo-header-element yotpo-header-actions ">
                <span className="y-label yotpo-review-date">04/27/23</span>
              </div>
            </div>
            <div className="yotpo-main ">
              <div
                className="content-title yotpo-font-bold"
                role="heading"
                aria-level="3"
              >
                We’ve had great experiences with
              </div>
              <div className="yotpo-clr"></div>
              <div className="yotpo-review-wrapper">
                <div className="content-review">
                  We’ve had great experiences with limos.com and will continue
                  to use them in the future. Always on time and courteous
                  drivers!
                </div>
              </div>
            </div>
            <div className="yotpo-footer "></div>
          </div>
        </div>

        <div className="yotpo">
          <div className="yotpo-review yotpo-regular-box">
            <div className="yotpo-header yotpo-verified-buyer ">
              <span className="yotpo-icon-profile yotpo-header-element pull-left">
                <span className="yotpo-user-letter">T</span>
                <span
                  className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                  data-type="toggleFade"
                  data-target="yotpo-tool-tip"
                  data-target-container="yotpo-header"
                ></span>
              </span>
              <div className="yotpo-header-element ">
                <span
                  className="y-label yotpo-user-name yotpo-font-bold pull-left"
                  aria-level="3"
                >
                  Terry B.
                </span>
                <div className="label-with-tooltip pull-left" aria-level="3">
                  <span
                    className="y-label yotpo-user-title yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    aria-describedby="274825440"
                  >
                    Verified Buyer
                  </span>
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-stars">
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                </div>
                <div className="yotpo-clr"></div>
              </div>
              <div className="yotpo-header-element yotpo-header-actions ">
                <span
                  className="y-label yotpo-review-date"
                  aria-label="review date 04/27/23"
                >
                  04/18/23
                </span>
              </div>
            </div>
            <div className="yotpo-main ">
              <div
                className="content-title yotpo-font-bold"
                role="heading"
                aria-level="3"
              >
                I have used this driver
              </div>
              <div className="yotpo-clr"></div>
              <div className="yotpo-review-wrapper">
                <div className="content-review">
                  I have used this driver a few times. He is very nice, always
                  on time and very personable. We hope we can continue to use
                  him for all our airport transports.
                </div>
              </div>
            </div>
            <div className="yotpo-footer "></div>
          </div>
        </div>

        <div className="yotpo">
          <div className="yotpo-review yotpo-regular-box">
            <div className="yotpo-header yotpo-verified-buyer ">
              <span className="yotpo-icon-profile yotpo-header-element pull-left">
                <span className="yotpo-user-letter">C</span>
                <span
                  className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                  data-type="toggleFade"
                  data-target="yotpo-tool-tip"
                  data-target-container="yotpo-header"
                ></span>
              </span>
              <div className="yotpo-header-element ">
                <span
                  className="y-label yotpo-user-name yotpo-font-bold pull-left"
                  aria-level="3"
                >
                  Carla L.
                </span>
                <div className="label-with-tooltip pull-left" aria-level="3">
                  <span
                    className="y-label yotpo-user-title yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    aria-describedby="274825440"
                  >
                    Verified Buyer
                  </span>
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-stars">
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                </div>
                <div className="yotpo-clr"></div>
              </div>
              <div className="yotpo-header-element yotpo-header-actions ">
                <span className="y-label yotpo-review-date">01/13/23</span>
              </div>
            </div>
            <div className="yotpo-main ">
              <div
                className="content-title yotpo-font-bold"
                role="heading"
                aria-level="3"
              >
                Ephraim is the FINEST, most
              </div>
              <div className="yotpo-clr"></div>
              <div className="yotpo-review-wrapper">
                <div className="content-review">
                  Ephraim is the FINEST, most careful and kind Driver. EVER.
                  (Well, Milton is too). These two Gentlemen have been driving
                  for me for about 4 years. They both are true and trusted
                  Professionals.
                </div>
              </div>
            </div>
            <div className="yotpo-footer "></div>
          </div>
        </div>

        <div className="yotpo">
          <div className="yotpo-review yotpo-regular-box">
            <div className="yotpo-header yotpo-verified-buyer ">
              <span className="yotpo-icon-profile yotpo-header-element pull-left">
                <span className="yotpo-user-letter">W</span>
                <span
                  className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                  data-type="toggleFade"
                  data-target="yotpo-tool-tip"
                  data-target-container="yotpo-header"
                ></span>
              </span>
              <div className="yotpo-header-element ">
                <span
                  className="y-label yotpo-user-name yotpo-font-bold pull-left"
                  aria-level="3"
                >
                  Wei O.
                </span>
                <div className="label-with-tooltip pull-left" aria-level="3">
                  <span
                    className="y-label yotpo-user-title yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    aria-describedby="274825440"
                  >
                    Verified Buyer
                  </span>
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-stars">
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                </div>
                <div className="yotpo-clr"></div>
              </div>
              <div className="yotpo-header-element yotpo-header-actions ">
                <span className="y-label yotpo-review-date">03/23/23</span>
              </div>
            </div>
            <div className="yotpo-main ">
              <div
                className="content-title yotpo-font-bold"
                role="heading"
                aria-level="3"
              >
                Great experience!
              </div>
              <div className="yotpo-clr"></div>
              <div className="yotpo-review-wrapper">
                <div className="content-review">
                  Allan was an amazing driver! He was patient and professional
                  all the way through. The booking process on the website was
                  also seamless and fuss-free.
                </div>
              </div>
            </div>
            <div className="yotpo-footer "></div>
          </div>
        </div>

        <div className="yotpo">
          <div className="yotpo-review yotpo-regular-box">
            <div className="yotpo-header yotpo-verified-buyer ">
              <span className="yotpo-icon-profile yotpo-header-element pull-left">
                <span className="yotpo-user-letter">A</span>
                <span
                  className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                  data-type="toggleFade"
                  data-target="yotpo-tool-tip"
                  data-target-container="yotpo-header"
                ></span>
              </span>
              <div className="yotpo-header-element ">
                <span
                  className="y-label yotpo-user-name yotpo-font-bold pull-left"
                  aria-level="3"
                >
                  Arturo P.
                </span>
                <div className="label-with-tooltip pull-left" aria-level="3">
                  <span
                    className="y-label yotpo-user-title yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    aria-describedby="274825440"
                  >
                    Verified Buyer
                  </span>
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-stars">
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                </div>
                <div className="yotpo-clr"></div>
              </div>
              <div className="yotpo-header-element yotpo-header-actions ">
                <span className="y-label yotpo-review-date">04/25/23</span>
              </div>
            </div>
            <div className="yotpo-main ">
              <div
                className="content-title yotpo-font-bold"
                role="heading"
                aria-level="3"
              >
                Great service
              </div>
              <div className="yotpo-clr"></div>
              <div className="yotpo-review-wrapper">
                <div className="content-review">
                  Driver was on time and courteous. Great driving. Vehicle was
                  nice and clean. Great experience overall
                </div>
              </div>
            </div>
            <div className="yotpo-footer "></div>
          </div>
        </div>
      </div>
    )
  }

  const openIntercomeLiveChatWindow = event => {
    event.preventDefault()
    var intercomeIcon = document.getElementsByClassName("intercom-launcher")[0]
    if (intercomeIcon) {
      intercomeIcon.click()
    }
  }

  const pageFooter = () => {
    return (
      <div className="page-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FaqListing />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <GoogleMap />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-12 footer-columns">
              <p className="footer-links-heading">Book Now</p>
              <div>
                <ul className="footer-main-links">
                  <li>
                    <a href="tel:8773265390">Call 877-326-5390 for Sales</a>
                  </li>

                  <li>
                    <a href="tel:8773845021">
                      Call 877-384-5021 for Customer Service
                    </a>
                  </li>

                  <li>
                    <a href="tel:8776059221">
                      Call 877-605-9221 for Operator Support
                    </a>
                  </li>

                  <li>
                    <a href="mailto:booking@limos.com">booking@limos.com</a>
                  </li>
                  <li>
                    <a href={routePaths.root}>Book Online &gt; </a>
                  </li>
                  <li>
                    <a href="" onClick={openIntercomeLiveChatWindow}>
                      Live Chat &gt;
                    </a>
                  </li>
                  <li>
                    <Link to={routePaths.siteMap}>SiteMap</Link>
                  </li>
                  <li>
                    <span className="hidden-text">
                      Call 877 590 5193 for additional Sales support
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-4 col-12  footer-columns">
              <p className="footer-links-heading">About</p>
              <div>
                <ul className="footer-main-links">
                  <li>
                    <a href={routePaths.siteTerms}>Terms</a>
                  </li>
                  <li>
                    <a href={routePaths.privacyPolicy}>Privacy</a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/limo-price-guide">
                      Pricing Guide
                    </a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/vehicle-guide">
                      Vehicle Guide
                    </a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/limos-101-pricing-and-planning-guide">
                      Limos 101
                    </a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/customers/faqs">FAQs </a>
                  </li>
                  <li>
                    <a href="https://www.limos.com/operators/join">
                      Partner with Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <SocialLinks />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="container-request-quote">
        <div className="request-quote-hero-banner">
          <div className="img-container">
            <div className="inner-contents-wrapper">
              <div className="banner-container">
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/contestImages/limos-quote-banner-v4.jpg"
                  className="banner-lg"
                />
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/contestImages/limos-quote-banner-v4.jpg"
                  className="banner-sm"
                />
              </div>
              <div className="sprite-search">
                <div className="sprites-bg">
                  <div className="promo-box">
                    20% off on all vehicles! Limited time only.
                  </div>
                  <div className="sprite-group">
                    <div className="vehicle-service-logo">
                      <img
                        src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Limos-logo-landing.png"
                        height="100%"
                        width="100%"
                      />
                    </div>

                    <div className="img-desc">
                      <span>
                        Find the best transportation deals to get <br />
                        you to any destination in style.
                      </span>
                    </div>
                    <div className="transport-image-sm">
                      <img src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Luxury-Fleet+Copy.png" />
                    </div>
                    <ul className="banner-sprites">
                      <li className="li-flex">
                        <div className="img-div">
                          <img
                            src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Luxury-Vehicles-limos.png"
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div className="li-desc">
                          Luxury Sedans,
                          <br /> SUVs, Limos, Vans,
                          <br /> Shuttles & Buses
                        </div>
                      </li>
                      <li className="li-flex">
                        <div className="img-div">
                          <img
                            src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Excellent-services.png"
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div className="li-desc">
                          5-star Service <br />
                          5-star Reviews
                        </div>
                      </li>
                      <li className="li-flex">
                        <div className="img-div">
                          <img
                            src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Priority-customer-service.png"
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div className="li-desc">
                          Priority Customer Service. Call now 877-326-5390
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="search-form-request-quote">
                  <NewSearchForm
                    pickupPlace={props.pickupPlace}
                    dropoffPlace={props.dropoffPlace}
                    setPlace={props.setPlace}
                    setVehicleOptions={props.setVehicleOptions}
                    partySize={props.partySize}
                    vehicleTypes={props.vehicleTypes}
                    pickupPlaceItem={props.pickupPlaceItem}
                    onPlaceQuery={props.onPlaceQuery}
                    pickupPlaceQueryResults={props.pickupPlaceQueryResults}
                    dropoffPlaceQueryResults={props.dropoffPlaceQueryResults}
                    tripWizardValues={props.tripWizardValues}
                    setSearchFormErrors={props.setSearchFormErrors}
                    searchFormErrors={props.searchFormErrors}
                    completeDate={props.completeDate}
                    returnDate={props.returnDate}
                    finishedTripWizard={props.finishedTripWizard}
                    createInitialSearchFormErrorState={
                      props.createInitialSearchFormErrorState
                    }
                    openChatWindow={props.openChatWindow}
                    errors={props.searchFormErrors}
                    setError={props.setError}
                    onSearchFormChange={props.onSearchFormChange}
                    searchForm={props.searchForm}
                    setSearchParams={props.setSearchParams}
                    roundTrip={props.roundTrip}
                    setRoundTrip={props.setRoundTrip}
                    setCustomPartySize={props.setCustomPartySize}
                    customPartySize={props.customPartySize}
                    images={[]}
                    formTitle={"Searching 112,000 Vehicles for Best Deals"}
                    formSubtitle={searchFormSubtitle}
                    formButtonText={"Submit Now for 20% Off!"}
                    pageName={"request-quote"}
                  />
                </div>
              </div>
              <div className="container-black-extend"></div>
            </div>
          </div>
        </div>
        <>
          <ContactForm />
        </>

        <div className="second-part">
          <div className="second-section-vehicle-banner">
            <div className="left-banner">
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Luxury-fleet-vans-shuttles.jpg"
                className="banner-lg"
              />
            </div>

            <div className="right-banner-container">
              <div className="right-banner-secondary-container">
                <div className="promo-banner ">
                  20% off on all vehicles! Limited time only.
                </div>

                <div className="service-vehicle-image">
                  <img
                    src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Limos-logo-landing.png"
                    height="100%"
                    width="100%"
                  />
                </div>
                <p className="right-vehicle-desc">
                  The ultimate destination to book your Luxury Vans,
                  <br /> Shuttles and Buses for any occasion.
                </p>
                <div class="contact-marketing">
                  Call Us now 877-326-5390 to Book!
                </div>
              </div>
            </div>
          </div>

          <div className="service-vehicle-container">
            <div className="transport-image-lg">
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Luxury-Fleet-Vehicle.png"
                width="100"
                height="100"
              />
            </div>
            <div className="rio-upper-container">
              <div class="rio-section-container">
                <div class="rio-section-title">
                  <h2> How does Limos.com work?</h2>
                </div>
                <div class="rio-section-description-container">
                  <p class="rio-section-description">
                    We search across <span class="bold">12,015+</span> operators
                    to find you the <span class="bold">best</span>&nbsp;deals{" "}
                    <span class="bold">&nbsp;globally</span> on all types of
                    vehicles. Including Sedans, SUVs, Limos, Vans, Shuttles and
                    Buses where luxury meets affordability.{" "}
                  </p>
                </div>
              </div>
              <section class="four-features">
                <div class="container">
                  <ul>
                    <li>
                      <img
                        src="https://limos-frontend.s3.amazonaws.com/frontend/CustomerService.jpg"
                        alt="CustomerService.jpg"
                      />
                      <p>
                        Priority 24/7 <br /> Customer Support
                      </p>
                    </li>
                    <li>
                      <img
                        src="https://limos-frontend.s3.amazonaws.com/frontend/BusinessTravel.jpg"
                        alt="BusinessTravel.jpg"
                      />
                      <p>
                        Worldwide Travel <br /> Solutions
                      </p>
                    </li>
                    <li>
                      <img
                        src="https://limos-frontend.s3.amazonaws.com/frontend/LuxuryVehicles.jpg"
                        alt="LuxuryVehicles.jpg"
                      />
                      <p>
                        Luxury Sedans to <br /> Motorcoaches
                      </p>
                    </li>
                    <li>
                      <img
                        src="https://limos-frontend.s3.amazonaws.com/frontend/professional-chauffeur.png"
                        alt="professional-chauffeur.png"
                      />
                      <p>
                        Professional Certified <br /> Chauffeurs
                      </p>
                    </li>
                    <li>
                      <img
                        src="https://assets.s3.amazonaws.com/images/front-end/new-landing/DutyofCare.jpg"
                        alt="new-landing/DutyofCare.jpg"
                      />
                      <p>
                        Trust and <br /> Safety
                      </p>
                    </li>
                  </ul>
                </div>
              </section>
            </div>
          </div>
          <div className="promo-banner-section">
            20% off on all vehicles! Limited time only.
          </div>

          <div className="popular-services">
            <span>
              Our Most Popular Services for Luxury Vans, Shuttles and Buses
            </span>
            <div className="services-container">
              <div className="services">
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Popular-vehicle-1.jpg"
                  height="100"
                  width="100"
                />

                <div className="service-vehicle-desc">
                  <p> Luxury Vans & Shuttles </p>
                  <p className="sub-text">starting at $187</p>
                </div>
              </div>
              <div className="services">
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Popular-vehicle-2.jpg"
                  height="100"
                  width="100"
                />

                <div className="service-vehicle-desc">
                  <p> Luxury Motor Coaches starting at $272</p>
                </div>
              </div>
              <div className="services">
                <img
                  src="https://assets.s3.amazonaws.com/images/front-end/contestImages/Popular-vehicle-3.jpg"
                  height="100"
                  width="100"
                />
                <div className="service-vehicle-desc">
                  <p>Tours & Excursions starting at $165</p>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-request-quote-team-sm">
            <button className="">Call Us at 877-326-5390 to Book!</button>
          </div>
          {reviewSection()}
        </div>

        {pageFooter()}
      </div>
    </>
  )
}

export default MarketLanding
