import React from "react"
import { BrowserRouter, Link, useLocation } from "react-router-dom"
import { routePaths } from "../../routes/routes"
import "./SocialLinks.scss"
import facebookIcon from "../../images/social-icons/youtube-icon.png"
import linkedInIcon from "../../images/social-icons/linked-in-icon.png"
import twitterIcon from "../../images/social-icons/twitter-icon.png"
import youtubeIcon from "../../images/social-icons/youtube-icon.png"
import instagramIcon from "../../images/social-icons/instagram.png"

export default class SocialLinks extends React.Component {
  render() {
    return (
      <div className="social-links-wrapper">
        <ul>
          {/* <li>
            <a href="">
              <img src={youtubeIcon} alt="" />
            </a>
          </li>
          <li>
            <a href="">
              <img src={linkedInIcon} alt="" />
            </a>
          </li> */}
          <li>
            <a
              href="https://www.instagram.com/limos.com1?igsh=czBjMzRkMWx1Z2Y3"
              target="_blank"
            >
              <img src={instagramIcon} alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/share/1tmPXwGYmAmJWeVY/?mibextid=LQQJ4d"
              target="_blank"
            >
              <img src={facebookIcon} alt="" />
            </a>
          </li>
        </ul>
      </div>
    )
  }
}
